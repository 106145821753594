<template>
  <div id="customer" class="pa-5">
    <v-navigation-drawer v-model="createCustomer" absolute temporary right width="75%">
      <CreateCustomer :onSuccess="LoadCustomer" :onClose="hideEditor" :customer="customerSimplified" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="showSendMail" absolute temporary stateless right width="75%">
      <SendMail :onSuccess="onCloseMailForm" :onClose="onCloseMailForm" ref="sendMail" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="createApp" absolute temporary stateless right width="75%">
      <AppointmentCreate :onSuccess="onSuccessAppCreate" :onClose="onCloseAppCreate" :customerId="customer.id" ref="createApp" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="createTask" absolute temporary stateless right width="75%">
      <TaskCreate :onSuccess="onSuccessTaskCreate" :onClose="onCloseTaskCreate" :customerId="customer.id" ref="createTask" />
    </v-navigation-drawer>

    <ProjectCreator ref="createProject" :onSave="projectAdded" :customerId="customer.id" />

    <Dlg ref="dialogs" :title="$t('customer.delete.title')" :body="$t('customer.delete.body')" :onOk="deleteCustomer" />

    <v-btn class="float-right my-2" @click="backToOverview()">{{ $t("customer.backToOverview") }}</v-btn>
    <h1>{{ customer.firstname }} {{ customer.name }}</h1>

    <v-card class="mt-5">
      <v-app-bar dense elevation="0" :style="style">
        <v-toolbar-title>{{ $t("customer.data.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="editCustomer()">
          <v-icon color="lime">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn text @click="deleteCustomerQuestion(customer.id)">
          <v-icon color="red">mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <small class="float-right"> {{ $t("customer.lastModification") }} [{{ customer.updatedAt | datetime }}] </small>
        <dl>
          <dt class="accent--text">{{ $t("customers.list.email") }}</dt>
          <dd>
            <v-icon v-if="false" @click="openSendMail(customer.email)">mdi-email-outline</v-icon>
            <a class="mailto" :href="`mailto:${customer.email}?subject=KLS - &cc=${customer.seller && customer.seller.email}`" target="_new">
              <v-icon>mdi-email-edit-outline</v-icon> {{ customer.email }}
            </a>
          </dd>
          <dt class="accent--text">{{ $t("customers.list.email2") }}</dt>
          <dd v-if="customer.email2">
            <v-icon v-if="false" @click="openSendMail(customer.email2)">mdi-email-outline</v-icon>
            <a class="mailto" :href="`mailto:${customer.email2}?cc=${customer.seller && customer.seller.email}`" target="_new">
              <v-icon>mdi-email-edit-outline</v-icon> {{ customer.email2 }}
            </a>
          </dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.phone") }}</dt>
          <dd>{{ customer.phone || "-" }}</dd>

          <dt class="accent--text">{{ $t("customers.list.mobile") }}</dt>
          <dd>{{ customer.mobile || "-" }}</dd>

          <dt class="accent--text">{{ $t("customers.list.mobile2") }}</dt>
          <dd v-if="customer.mobile2">{{ customer.mobile2 || "-" }}</dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.birthday") }}</dt>
          <dd v-if="customer.birthdate">{{ customer.birthdate | date }}</dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.birthPlace") }}</dt>
          <dd v-if="customer.birthplace">{{ customer.birthplace }}</dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.familyStatus") }}</dt>
          <dd v-if="customer.familystatus">{{ customer.familystatus }}</dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.seller") }}</dt>
          <dd v-if="customer.seller">
            <v-icon v-if="false" @click="openSendMail(customer.seller.email)">mdi-email-outline</v-icon>
            <a class="mailto" :href="`mailto:${customer.seller.email}?subject=KLS - `" target="_new">
              <v-icon>mdi-email-edit-outline</v-icon> {{ customer.seller.name }}
            </a>
          </dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.firstContact") }}</dt>
          <dd v-if="customer.firstcontact">{{ customer.firstcontact | date }}</dd>
          <dd v-else>-</dd>

          <dt class="accent--text">{{ $t("customers.list.addresses") }}</dt>
          <dd v-if="customer.addresses && customer.addresses.length">
            <div v-for="(addr, i) in customer.addresses" :key="i">{{ addr.street }} {{ addr.house_number }}, {{ addr.post_code }} {{ addr.place }}</div>
          </dd>
          <dd v-else>-</dd>

          <dt class="accent--text ma-1">{{ $t("customers.app.info") }}</dt>
          <dd>
            {{ $t(`customers.app.state[${customer.appActivated}]`) }}

            <v-btn @click="toggleApp(customer)" class="ml-15"> {{ $t(`customers.app.action[${customer.appActivated}]`) }} </v-btn>
            <v-btn @click="resetPwd(customer)" class="ml-15"> {{ $t(`customers.pwd.reset`) }} </v-btn>
          </dd>
        </dl>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-app-bar dense elevation="0" :style="style">
        <v-toolbar-title>{{ $t("customer.projects.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch class="mt-5" v-model="showArchivedProjects" label="Archivierte Projekte anzeigen"></v-switch>
        <AddButton :onClick="addProject" />
      </v-app-bar>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left accent--text">{{ $t("projects.infos.identifier") }}</th>
                <th class="text-left accent--text">{{ $t("projects.infos.title") }}</th>
                <th class="text-left accent--text">{{ $t("projects.states.title") }}</th>
                <th class="text-left accent--text">{{ $t("projects.types.title") }}</th>
                <th class="text-left accent--text">{{ $t("projects.infos.volume") }}</th>
                <th class="text-left accent--text">{{ $t("projects.infos.projectType") }}</th>
                <th class="text-left accent--text">{{ $t("projects.infos.seller") }}</th>
                <th class="text-left accent--text">{{ $t("projects.infos.owner") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in projects" :key="item.name" @click="goToProject(item.id)" class="pointer">
                <td>{{ item.identifier }}</td>
                <td>{{ item.name }}</td>
                <td>{{ $t("projects.states." + item.state) }}</td>
                <td>{{ $t("projects.types." + item.position) }}</td>
                <td>{{ item.volume }}</td>
                <td>{{ item.projectType && item.projectType.name }}</td>
                <td>{{ item.seller && item.seller.name }}</td>
                <td>{{ item.owner && item.owner.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-app-bar dense elevation="0" :style="style">
        <v-toolbar-title>{{ $t("customer.appointments.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch class="mt-5" v-model="showProjectAppointments" label="Termine aus Projekten anzeigen"></v-switch>
        <AddButton :onClick="addAppointment" />
      </v-app-bar>
      <v-card-text>
        <Appointments :appointments="customer.appointments" :onAction="doNothing" :showActions="false" />
        <template v-if="showProjectAppointments">
          <div v-for="(p, i) in appointments" :key="i">
            <h3>{{ p.name }}</h3>
            <Appointments :appointments="p.appointments" :onAction="doNothing" :showActions="false" />
          </div>
        </template>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-app-bar dense elevation="0" :style="style">
        <v-toolbar-title>{{ $t("customer.tasks.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch class="mt-5" v-model="showProjectTasks" label="Aufgaben aus Projekten anzeigen"></v-switch>
        <AddButton :onClick="addTask" />
      </v-app-bar>
      <v-card-text>
        <Tasks :tasks="customer.tasks" :onAction="doNothing" :showFilters="false" :showActions="false" />
        <template v-if="showProjectTasks">
          <div v-for="(p, i) in tasks" :key="i">
            <h3>{{ p.name }}</h3>
            <Tasks :tasks="p.tasks" :onAction="doNothing" :showFilters="false" :showActions="false" />
          </div>
        </template>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-app-bar dense elevation="0" :style="style">
        <v-toolbar-title>{{ $t("customer.mails.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch class="mt-5" v-model="showProjectMails" label="Mails aus Projekten anzeigen"></v-switch>
      </v-app-bar>
      <v-card-text>
        <Mails :items="customer.mails" :onAction="doNothing" :showFilters="false" :showActions="false" />
        <template v-if="showProjectMails">
          <div v-for="(p, i) in mails" :key="i">
            <h3>{{ p.name }}</h3>
            <Mails :items="p.mails" />
          </div>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatMixin, formMixin } from "@/helpers/mixins";
import { getColor } from "@/services/theme";
import axios from "axios";

import Dlg from "@/components/_common/dialogs";
import AddButton from "@/components/_common/addbutton";
import ProjectCreator from "@/components/projects/projectcreator.vue";
import Appointments from "@/components/appointments/appointmentslist";
import Tasks from "@/components/tasks/tasklist";
import Mails from "@/components/mails/maillist";

import CreateCustomer from "@/components/customers/customercreate.vue";
import SendMail from "@/components/mails/mailsend.vue";
import AppointmentCreate from "@/components/appointments/appointmentcreate.vue";
import TaskCreate from "@/components/tasks/taskcreate.vue";

export default {
  mixins: [formatMixin, formMixin],

  data() {
    return {
      showSendMail: false,
      createCustomer: false,
      createApp: false,
      createTask: false,
      customer: {},
      projects: [],
      appointments: [],
      tasks: [],
      mails: [],
      showArchivedProjects: false,
      showProjectAppointments: false,
      showProjectTasks: false,
      showProjectMails: false,
    };
  },

  props: {},

  computed: {
    customerSimplified: function () {
      let c = { ...this.customer };
      delete c.projects;
      return c;
    },
    style: function () {
      return `background: ${getColor("navbg")}; background: linear-gradient(0deg, transparent 10%, ${getColor("navbg")} 75%;`;
    },
  },

  watch: {
    showArchivedProjects: function (val) {
      this.loadProjects("", "projects", val);
      this.showProjectAppointments = false;
      this.showProjectTasks = false;
    },
    showProjectAppointments: function (val) {
      this.loadProjects("appointments", "appointments", val);
    },
    showProjectTasks: function (val) {
      this.loadProjects("tasks", "tasks", val);
    },
    showProjectMails: function (val) {
      this.loadProjects("mails", "mails", val);
    },
  },

  mounted() {
    this.LoadCustomer();
  },

  methods: {
    LoadCustomer: function () {
      this.createCustomer = false;
      this.$store.dispatch("loading", true);
      if (this.$route.params.id) {
        axios
          .get(`/api/customers/${this.$route.params.id}?show=details`)
          .then((response) => {
            this.customer = response.data;
            this.projects = response.data.projects;

            this.$store.dispatch("loading", false);
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("loading", false);
          });
      } else {
        this.reset();
      }
    },

    loadProjects: function (inc, varname, val) {
      if (val) {
        let url = `/api/projects?customer=${this.$route.params.id}&include=${inc}`;

        if (this.showArchivedProjects) {
          url += "&state=all";
        }

        this.$store.dispatch("loading", true);
        axios
          .get(url)
          .then((response) => {
            this[varname] = response.data;
            this.$store.dispatch("loading", false);
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("loading", false);
          });
      } else {
        this[varname] = this.customer[varname];
      }
    },

    editCustomer() {
      this.createCustomer = true;
    },
    hideEditor() {
      this.createCustomer = false;
    },
    toggleApp(c) {
      axios
        .post(`/api/customers/${this.$route.params.id}`, { appActivated: !c.appActivated })
        .then((response) => {
          this.customer.appActivated = response.data.appActivated;
          console.log(response.data);
          this.$store.dispatch("showSnackMessage", { message: "App status changed" });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: "App status change error", type: "error" });
        });
    },
    resetPwd(c) {
      axios
        .get(`/api/app/sendpwdresetrequest?email=${c.email}`)
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("showSnackMessage", { message: "New pwd sent to primary Email" });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: "App status change error", type: "error" });
        });
    },

    addProject: function () {
      this.$refs.createProject.show();
    },
    projectAdded: function (prj) {
      this.goToProject(prj.id);
    },
    goToProject: function (pId) {
      this.$router.push(`/projects/${pId}?from=customer`);
    },

    openSendMail: function (to) {
      this.$refs.sendMail.load({ to: to });
      this.showSendMail = true;
    },
    onCloseMailForm: function () {
      this.showSendMail = false;
    },

    addAppointment: function () {
      this.$refs.createApp.load();
      this.createApp = true;
    },
    onCloseAppCreate: function () {
      this.createApp = false;
    },
    onSuccessAppCreate: function () {
      this.createApp = false;
      this.LoadCustomer();
    },

    addTask: function () {
      this.$refs.createTask.load();
      this.createTask = true;
    },
    onCloseTaskCreate: function () {
      this.createTask = false;
    },
    onSuccessTaskCreate: function () {
      this.createTask = false;
      this.LoadCustomer();
    },

    backToOverview: function () {
      this.$router.push(`/customers`);
    },

    deleteCustomerQuestion: function (id) {
      this.$refs.dialogs.show(id);
    },
    deleteCustomer: function (id) {
      axios
        .delete(`/api/customers/${id}`)
        .then(() => {
          this.backToOverview();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    doNothing: function () {},
  },

  components: {
    Dlg,
    AddButton,
    CreateCustomer,
    ProjectCreator,
    SendMail,
    Appointments,
    Tasks,
    Mails,
    AppointmentCreate,
    TaskCreate,
  },
};
</script>

<style lang="scss" scoped>
#customer {
  dl {
    padding: 0.5em;
  }
  dt {
    float: left;
    clear: left;
    width: 150px;
    text-align: right;
    font-weight: bold;
  }
  dt::after {
    content: ":";
  }
  dd {
    margin: 0 0 0 25%;
    padding: 0 0 0.5em 0;
  }
  .mailto {
    text-decoration: none;
  }
}
</style>
