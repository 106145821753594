<template>
  <v-card elevation="0">
    <PanelHeader :onSave="save" :title="$t('tasks.edit.title')" :tabIndex="9004" :onClose="onClose" />
    <v-card-text>
      <v-layout row wrap>
        <v-col cols="12" md="9">
          <v-text-field v-model="task.name" :label="$t('tasks.edit.name')" :tabIndex="9001"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <DropDate :model="task.dueDate" :tabIndex="9003" :onClose="setDate" :label="$t('tasks.edit.date')" />
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="task.assigneeId"
            :items="users"
            item-value="id"
            item-text="name"
            :label="$t('tasks.edit.assigned')"
            required
            :tabIndex="9002"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="!projectId">
          <v-select
            v-model="task.customerId"
            :items="customers"
            item-value="id"
            item-text="name"
            :label="$t('tasks.edit.customer')"
            required
            :tabIndex="9002"
          ></v-select>
        </v-col>

        <v-col cols="12" md="12">
          {{ $t("tasks.edit.body") }}
          <ckeditor :editor="editor" v-model="task.body" :config="editorConfig" :tabIndex="9005"></ckeditor>
        </v-col>
        <v-col cols="12">
          <v-alert text icon="mdi-help" border="left" color="green" block>
            {{ $t("tasks.edit.help") }}
          </v-alert>
        </v-col>
      </v-layout>
      <pre>{{ task }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";
import DropDate from "@/components/_common/dropdate";

export default {
  mixins: [formatMixin, formMixin, richMixin],

  components: {
    PanelHeader,
    DropDate,
  },

  props: {
    projectId: {
      type: String,
      default: () => null,
    },
    customerId: {
      type: String,
      default: () => null,
    },
    invId: {
      type: String,
      default: () => null,
    },
    onSuccess: {
      type: Function,
    },
    onClose: {
      type: Function,
    },
    onAction: {
      type: Function,
    },
  },

  data() {
    return {
      users: [],
      customers: [],
      task: {
        body: "",
      },
    };
  },

  created() {
    this.task.projectId = this.projectId;
    this.task.customerId = this.customerId;
    this.task.investmentId = this.investmentId;
    this.loadData();
  },

  methods: {
    load: function(params) {
      if (params && params.id) {
        axios
          .post(`/api/tasks/${params.id}`)
          .then((res) => {
            this.task = res.data;
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      } else {
        this.task = {
          projectId: this.projectId,
          customerId: this.customerId,
          investmentId: this.investmentId,
        };
      }
    },
    loadData: function() {
      axios
        .get(`/api/users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`/api/customers`)
        .then((response) => {
          this.customers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    init: function(pId, iId, cId) {
      this.task = {
        body: "",
        assigneeId: null,
        dueDate: null,
      };
      if (pId) {
        this.task.projectId = pId;
      }
      if (iId) {
        this.task.investmentId = iId;
      }
      if (iId) {
        this.task.customerId = cId;
      }
    },

    setDate: function(dt) {
      this.task.dueDate = dt;
    },

    save: function() {
      axios
        .post(`/api/tasks/${this.task.id || ""}`, this.task)
        .then((msg) => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
          this.init();
          this.onSuccess(msg.data);
          this.onClose();
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>
