<template>
  <v-container fluid grid-list-xl class="pa-0 timeLine tasks" v-bind:class="{ dark: isDark }">
    <SearchHeader v-if="showFilters" :onAction="search" :height="50">
      <v-layout>
        <v-flex>
          <v-row>
            <v-col cols="9" md="4" class="mt-1">
              <v-text-field v-model="f.search" append-icon="mdi-magnify" :label="$t('common.tables.filter')" :tabIndex="1201" dense hide-details></v-text-field>
            </v-col>
            <v-col cols="3" md="8">
              <v-chip-group class="hidden-sm-and-down" v-model="selectedRange" column mandatory active-class="primary--text">
                <v-chip filter outlined v-for="(f, i) in f.range" :key="i">
                  {{ f }}
                </v-chip>
              </v-chip-group>
              
              <div class="hidden-md-and-up">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark text v-on="on"> <v-icon left>mdi-arrow-down</v-icon> {{ f.range[selectedRange] }}</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(f, i) in f.range" :key="i" @click="selectedRange = i">
                      <v-list-item-title :class="selectedRange == i ? 'lime--text' : ''">
                        <v-icon :color="selectedRange == i ? 'lime' : ''">mdi-arrow-right-bold-hexagon-outline</v-icon> {{ f }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </SearchHeader>

    <Dlg ref="delDialog" :title="$t('tasks.delete.title')" :body="$t('tasks.delete.body')" :onOk="deleteTask" />

    <v-timeline dense clipped align-top v-if="gTasks">
      <v-timeline-item color="grey" small v-for="(grp, i) in gTasks" :key="i" class="timeElement">
        <div class="timeHeader" :style="timeHeaderStyle">{{ i != "null" ? i : $t("tasks.list.noDueDate") }}</div>

        <div v-for="(task, j) in grp" :key="j" v-bind:class="listClass(task)" class="task mhover">
          <v-checkbox
            v-if="task.state != 'archived'"
            v-model="task.checked"
            color="primary"
            class="checker"
            @click="toggleState(task.id, task.state)"
          ></v-checkbox>
          <div class="ct">
            <div class="taskActions">
              <small v-if="task.state != 'archived' && task.assignee">
                {{ $t("tasks.list.task.for") }} <strong>{{ task.assignee.name }}</strong>
              </small>
              <small class="pr-2">
                {{ $t("tasks.list.task.from") }} <strong>{{ task.owner && task.owner.name }}</strong>
              </small>
              <template v-if="task.state == 'open' && (!task.assigneeId || task.assigneeId == userId || task.ownerId == userId)">
                <v-icon @click="showTaskOwner" left color="lime darken-2" :data-taskId="task.id">mdi-arrow-right-circle</v-icon>
              </template>
              <template v-if="task.assigneeId == userId">
                <template v-if="task.state === 'open'">
                  <v-icon @click="onAction('createTask', { id: task.id })" left color="lime darken-2">mdi-pencil</v-icon>
                  <v-icon @click="deleteTaskQuestion(task.id)" color="red">mdi-delete</v-icon>
                </template>
                <template v-if="task.state === 'done'">
                  <v-icon @click="archiveTask(task.id)">mdi-archive</v-icon>
                </template>
                <template v-if="task.state === 'archived'">
                  <v-icon @click="reactivateTask(task.id)">mdi-archive-arrow-up-outline</v-icon>
                </template>
              </template>
            </div>

            <v-btn text class="float-right mr-5" v-if="task.customer" @click="goTo(`/customers/${task.customerId}`)">
              <v-icon left color="primary">mdi-account-tie</v-icon> {{ task.customer.name }}
            </v-btn>

            <v-btn text class="float-right mr-5" v-if="task.project" @click="goTo(`/projects/${task.projectId}`)">
              <v-icon left color="primary">mdi-home-account</v-icon> {{ task.project.name }}
            </v-btn>

            <div @click="toggleShowBody(task)">
              {{ task.name || "Undefinied" }} {{ task.dueDate | time }}
              <v-icon v-if="showBody(task)">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
              <div class="body" v-html="task.body"></div>
            </div>
          </div>
        </div>
      </v-timeline-item>

      <v-menu v-model="showOwner" :position-x="x" :position-y="y" absolute offset-y close-on-click close-on-content-click>
        <v-list>
          <v-list-item v-for="(item, i) in data.users" :key="i" @click="setAssignee(selectedTask, item)">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-timeline>

    <div v-else>{{ $t("tasks.list.noTasks") }}</div>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import { isDark, getColor } from "@/services/theme";
import { formatMixin, listMixin } from "@/helpers/mixins";
import Dlg from "@/components/_common/dialogs";
import SearchHeader from "@/components/_common/searchheader";

export default {
  mixins: [formatMixin, listMixin],

  components: {
    Dlg,
    SearchHeader,
  },

  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    onAction: {
      type: Function,
    },
    showFilters: {
      type: Boolean,
      default: () => true,
    },
    showActions: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      data: {
        users: [],
      },
      showOwner: false,
      selectedTask: null,
      x: 0,
      y: 0,
      showBodyList: [],
      query: "",
      scrolled: false,
      f: {
        search: "",
        range: [
          this.$i18n.t("tasks.range.all"),
          this.$i18n.t("tasks.range.overdue"),
          this.$i18n.t("tasks.range.today"),
          this.$i18n.t("tasks.range.tomorrow"),
          this.$i18n.t("tasks.range.next7days"),
        ],
      },
      selectedRange: null,
    };
  },

  computed: {
    ...mapGetters(["userId"]),
    isDark: function() {
      return isDark();
    },
    timeHeaderStyle: function() {
      return `color: ${getColor("accent")}`;
    },
    gTasks: function() {
      let t = this.tasks;
      if (this.f.search) {
        let q = this.f.search.toLowerCase();
        t = this.tasks.filter(
          (task) =>
            (task.name && task.name.toLowerCase().includes(q)) ||
            (task.body && task.body.toLowerCase().includes(q)) ||
            task.owner.name.toLowerCase().includes(q) ||
            (task.assignee && task.assignee.name.toLowerCase().includes(q))
        );
      }

      if (this.selectedRange > 0) {
        let now = moment();
        now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        switch (this.selectedRange) {
          case 1:
            t = t.filter((t) => t.dueDate && moment(t.dueDate).diff(now, "days") < 0);
            break;
          case 2:
            t = t.filter((t) => t.dueDate && moment(t.dueDate).diff(now, "days") == 0);
            break;
          case 3:
            t = t.filter((t) => t.dueDate && moment(t.dueDate).diff(now, "days") == 1);
            break;
          case 4:
            t = t.filter((t) => {
              let d = moment(t.dueDate).diff(now, "days");
              return t.dueDate && d >= 0 && d <= 7;
            });
            break;
        }
      }

      return this.groupList(t);
    },
    owner() {
      if (this.project && this.project.ownerId) {
        let o = this.data.users && this.data.users.find((element) => element.id == this.project.ownerId);
        if (o) return o;
      }
      return {};
    },
  },

  mounted() {
    this.loadDatas();
  },
  watch: {
    tasks: function() {
      if (!this.scrolled && this.$route.params.id) {
        this.showBodyList = this.tasks.filter((t) => t.selected).map((t) => t.id);
        if (this.showBodyList.length > 0) {
          setTimeout(() => {
            this.scrollMeTo(".selected");
          }, 100);
        }
        this.scrolled = true;
      }
    },
  },

  methods: {
    search: function(query) {
      this.query = query;
    },
    sameDay: function(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    },
    showTaskOwner: function(e) {
      this.selectedTask = e.target.dataset.taskid;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showOwner = true;
      });
    },
    loadDatas: function() {
      axios
        .get(`/api/users`)
        .then((response) => {
          this.data.users = response.data.map((u) => {
            return { id: u.id, name: u.name };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    groupList: function(lst) {
      if (!lst || lst.length == 0) {
        return null;
      }
      lst.forEach((d) => {
        d.fDate = d.dueDate ? this.getDate(d.dueDate) : null;
        d.isDelayed = d.dueDate && new Date(d.dueDate) < new Date();
        d.checked = d.state != "open";
      });
      return this.groupBy(lst, "fDate");
    },
    listClass: function(t) {
      return {
        isDelayed: t.isDelayed,
        done: t.state != "open",
        archived: t.state == "archived",
        hasDate: !t.dueDate,
        selected: this.showBodyList.includes(t.id),
      };
    },
    toggleState: function(id, currentState) {
      let newState = currentState == "done" ? "open" : "done";
      axios
        .get(`/api/tasks/${id}/${newState}`)
        .then(() => {
          this.tasks.find((t) => t.id == id).state = newState;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setAssignee: function(taskid, user) {
      axios
        .post(`/api/tasks/${taskid}`, { assigneeId: user.id })
        .then(() => {
          let t = this.tasks.find((t) => t.id == taskid);
          t.assigneeId = user.id;
          t.assignee = user;
          Vue.set(
            this.tasks,
            this.tasks.findIndex((t) => t.id === taskid),
            t
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTaskQuestion: function(id) {
      this.$refs.delDialog.show(id);
    },
    deleteTask: function(id) {
      axios
        .delete(`/api/tasks/${id}`)
        .then(() => {
          this.tasks = this.tasks.filter((t) => t.id != id);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    archiveTask: function(id) {
      axios
        .post(`/api/tasks/${id}`, {
          state: "archived",
          archivedDate: new Date(),
        })
        .then(() => {
          this.tasks = this.tasks.filter((t) => t.id != id);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    reactivateTask: function(id) {
      axios
        .get(`/api/tasks/${id}/open`)
        .then(() => {
          this.tasks = this.tasks.filter((t) => t.id != id);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    scrollMeTo(refName) {
      this.$vuetify.goTo(refName, {
        duration: 500,
        offset: 10,
        easing: "easeInOutCubic",
      });
    },

    showBody: function(task) {
      return this.showBodyList.includes(task.id);
    },
    toggleShowBody: function(task) {
      if (this.showBodyList.includes(task.id)) {
        this.showBodyList = this.showBodyList.filter((tid) => tid != task.id);
      } else {
        this.showBodyList.push(task.id);
      }
    },

    goTo: function(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
.tasks {
  .task {
    clear: both;
    margin: 0 1rem 0.5rem 0;
    padding: 0.25rem 1rem 0.25rem 0;
    min-height: 2.25rem;
    line-height: 2.25rem;

    &:hover {
      text-decoration: none;
    }

    .ct {
      margin-left: 1rem;
    }

    .body {
      display: none;
      margin: 1rem 0 1rem 1rem;
      padding: 0.5rem;
    }

    .checker {
      float: left;
      margin: 2px 0 0 5px;
      height: 2.25rem;
    }

    &.isDelayed {
      color: rgb(182, 48, 0);
    }
    &.hasDate {
      color: green;
    }
    &.done {
      color: grey;
      text-decoration: line-through;
      background-color: #dddddd;
    }

    &.selected {
      border-left: 6px solid silver;
        
      .body {
        display: block;
      }

      &:hover {
        background-color: silver;        
      }
    }

    .taskActions {
      float: right;
      text-align: right;
    }
  }
  &.dark {
    .task {
      background-color: transparent;

      &:hover {
        background-color: #eeeeee;
      }

      &.selected {
        .body {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
