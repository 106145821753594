<template>
  <v-container fluid class="pa-0 timeLine appointments">
    <Dlg ref="dialogs" :title="$t('calendar.delete.title')" :body="$t('calendar.delete.body')" :onOk="deleteAppointment" />

    <v-timeline dense clipped align-top v-if="appointments && appointments.length > 0">
      <v-timeline-item color="grey" small v-for="appointment in appointments" :key="appointment.id" class="timeElement">
        <div class="timeHeader" :style="timeHeaderStyle">
          <strong>{{ $t("calendar.list.dateTime") }}:</strong> {{ appointment.start | datetime }} - {{ appointment.end | datetime }}
        </div>
        <div class="timeContent appointment">
          <div class="float-right" v-if="showActions">
            <v-icon color="lime" @click="onAction('createAppointment', { id: appointment.id })">mdi-pencil</v-icon>
            <v-icon color="red" @click="deleteAppointmentQuestion(appointment.id)">mdi-delete</v-icon>
          </div>

          <dl>
            <dt>{{ $t("calendar.list.title") }}</dt>
            <dd>{{ appointment.name }}</dd>
            <dt>{{ $t("calendar.list.body") }}</dt>
            <dd v-html="appointment.body"></dd>
          </dl>
        </div>
      </v-timeline-item>
    </v-timeline>

    <div v-else>{{ $t("calendar.list.noResults") }}</div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getColor } from "@/services/theme";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";
import Dlg from "@/components/_common/dialogs";

export default {
  mixins: [formatMixin, formMixin, listMixin],

  data() {
    return {};
  },

  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
    onAction: {
      type: Function,
    },
    showActions: {
      type: Boolean,
      default: () => true,
    },
  },

  computed: {
    timeHeaderStyle: function() {
      return `color: ${getColor("accent")}`
    },
  },

  mounted() {},

  methods: {
    deleteAppointmentQuestion: function(id) {
      this.$refs.dialogs.show(id);
    },
    deleteAppointment: function(id) {
      axios
        .delete(`/api/appointments/${id}`)
        .then(() => {
          this.appointments = this.appointments.filter((t) => t.id != id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  components: {
    Dlg,
  },
};
</script>

<style lang="scss">
.appointments {
  dt {
    font-weight: bold;
    float: left;
  }
  dd {
    margin-left: 70px;
  }
}
</style>
