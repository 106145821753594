<template>
  <v-card elevation="0">
    <PanelHeader :onSave="sendMail" :title="$t('mails.edit.title')" :tabIndex="4004" :onClose="onClose" />
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="mail.to" :label="$t('mails.edit.to')" clearable :tabIndex="4001"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="mail.cc" :label="$t('mails.edit.cc')" clearable :tabIndex="4001"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="mail.replyTo" :label="$t('mails.edit.replyTo')" clearable :tabIndex="4001"></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="mail.subject" :label="$t('mails.edit.subject')" clearable :tabIndex="4002"></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              {{ $t("mails.edit.body") }}
              <ckeditor :editor="editor" v-model="mail.body" :config="editorConfig" :tabIndex="4003"></ckeditor>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("mails.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <pre>{{mail}}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";
import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";

export default {
  mixins: [formatMixin, formMixin, richMixin],

  components: {
    PanelHeader,
  },

  props: {
    projectId: {
      type: String,
      default: () => "",
    },
    onSuccess: {
      type: Function,
    },
    onClose: {
      type: Function,
    },
  },

  data() {
    return {
      valid: false,
      mail: {
        to: null,
        subject: "",
        body: "",
      },
    };
  },

  created: function() {
    this.mail.to = this.to;
    this.mail.body = this.user.profile.mailSignature? "<p></p>" + this.user.profile.mailSignature: "";
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.mail = {};
    },

    load: function(params) {
      if (params) {
        this.mail.to = params.to || "";
        this.mail.cc = params.cc || "";
        this.mail.replyTo = this.user.email || "";
        this.mail.subject = params.subject || "";
      }
    },

    sendMail: function() {
      if (this.$refs.form.validate()) {
        this.mail.projectId = this.projectId;

        axios
          .post(`/api/mails/send`, this.mail)
          .then((res) => {
            this.$store.dispatch("showSnackMessage", { message: "Data saved." });
            this.onSuccess(res.data);
            this.onClose();
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  },
};
</script>
