<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <PanelHeader :title="$t('projects.edit.title')" :tabIndex="6003" :onSave="save" :onClose="close" />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="8">
              <v-text-field v-model="project.name" :rules="rules.required" :label="$t('projects.edit.name')" required :tabIndex="6001" autofocus></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="project.identifier"
                :rules="rules.required"
                :label="$t('projects.edit.identifier')"
                required
                :tabIndex="6002"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("projects.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
        <pre>{{ project }}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

import { formatMixin, formMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";

export default {
  mixins: [formatMixin, formMixin],

  components: {
    PanelHeader,
  },

  props: {
    onSave: {
      type: Function,
    },
    customerId: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      dialog: false,
      valid: false,
      project: {
        name: "",
        identifier: Math.random()
          .toString(36)
          .substring(5)
          .toUpperCase(),
      },
    };
  },

  methods: {
    show: function(projectId) {
      if (projectId) {
        axios
          .get(`/api/projects/${projectId}`)
          .then((response) => {
            this.project = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.reset();
      }

      this.dialog = true;
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.project = {
        name: "",
        identifier: Math.random()
          .toString(36)
          .slice(2, 7)
          .toUpperCase(),
      };
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let prj = { ...this.project };
        delete prj.createdAt;
        delete prj.updatedAt;

        if (this.customerId) {
          prj.customerId = this.customerId;
        }

        axios
          .post(`/api/projects/${prj.id || ""}`, prj)
          .then((msg) => {
            this.$store.dispatch("showSnackMessage", { message: "Data saved" });
            this.close();
            this.onSave(msg.data);
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
    close: function() {
      this.dialog = false;
    },
  },
};
</script>
