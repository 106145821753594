<template>
  <v-container fluid class="pa-0 mails timeLine">
    <v-timeline dense clipped align-top v-if="items.length">
      <v-timeline-item color="grey" small v-for="(item, i) in mails" :key="i" class="timeElement">
        <div class="timeHeader accent--text">
          <strong>{{ $t("mails.list.sentDate") }}:</strong> {{ item.maildate | datetime }}
        </div>
        <div class="timeContent mail" v-bind:class="{ active: item && item.id == toggler }">
          <div @click="toggleBody(item)">
            <div class="headers">
              <v-icon v-if="item && item.id == toggler" class="float-right">mdi-chevron-up</v-icon>
              <v-icon v-else class="float-right">mdi-chevron-down</v-icon>
              <div>
                <strong>{{ $t("mails.list.from") }}:</strong> <v-icon left> mdi-account </v-icon>{{ item.from }}
              </div>
              <div>
                <strong>{{ $t("mails.list.to") }}: </strong> <v-icon left> mdi-account </v-icon>{{ item.to }}
              </div>
            </div>
            <div class="subject">
              <strong>{{ $t("mails.list.subject") }}:</strong> {{ item.subject }}
              <v-icon v-if="item.attachments && item.attachments.length">mdi-paperclip</v-icon>
            </div>
          </div>

          <div class="toggeling attach" v-if="item.attachments">
            <span v-for="(a, i) in item.attachments" :key="i">
              <v-icon>mdi-paperclip</v-icon>
              <a :href="`/api/mails/attachment/${a.id}`" target="_new">{{ a.name }}</a>
            </span>
          </div>

          <div class="toggeling" v-html="item.body"></div>
        </div>
      </v-timeline-item>
    </v-timeline>
    <div v-else>{{ $t("mails.list.noResults") }}</div>
  </v-container>
</template>

<script>
import { formatMixin, formMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, formMixin],

  components: {},

  data: () => ({
    toggler: "",
  }),

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    mails: function() {
      return this.items.map((m) => {
        m.body = m.body.replace(/\n/i, "<br />");
        return m;
      });
    },
  },

  methods: {
    openEditor: function() {
      this.$refs.editor.show(null);
    },
    toggleBody: function(item) {
      if (this.toggler == item.id) {
        this.toggler = null;
      } else {
        this.toggler = item.id;
      }
    },
  },
};
</script>

<style lang="scss">
.mails {
  .mail {
    .headers > div {
      float: left;
      margin-right: 1rem;
    }

    .subject,
    .body {
      clear: both;
    }

    .body {
      display: none;
      border-top: 1px solid silver;
      margin-top: 1rem;
      padding-top: 1rem;
    }

    .attach {
      border-top: 1px solid silver;
      border-bottom: 1px solid grey;
      padding: 1rem 0;
      margin: 1rem 0;
    }

    .toggeling {
      display: none;
    }

    &.active {
      color: black;
      border: 3px solid grey;
      border-radius: 5px;
      padding-right: 1rem;

      .toggeling {
        display: block;
      }
    }
  }
}
</style>
