<template>
  <DefaultLayout>
    <v-layout>
      <v-flex>
        <CustomerDetails/>
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import CustomerDetails from "@/components/customers/customerdetails.vue";

export default {
  name: "C",
  data: function() {
    return {
      sendMail: false,
      selectedRendering: 0,
    };
  },
  computed: {},
  components: {
    DefaultLayout,
    CustomerDetails,
  },
  methods: {
    onFilterChange: function(val) {
      this.selectedRendering = val;
    },
  },
};
</script>
