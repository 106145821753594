<template>
  <v-dialog v-model="dialog" width="50%">
    <v-card class="dlg">
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ title }}
      </v-card-title>
      <br />
      <v-card-text v-if="!hasSlotData">
        {{ body }}
      </v-card-text>
      <v-card-text v-else>
        <v-form v-model="formValid" ref="dform" :class="formClass">
          <v-container>
            <slot />
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="lime darken-1" @click="okBtn()">
          {{ ok }}
        </v-btn>
        <v-btn color="orange darken-1" text @click="dialog = false">
          {{ cancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dlg",
  data: function() {
    return {
      dialog: false,
      val: null,
      formValid: false,
      uid: Math.floor(Math.random() * 1000)
    };
  },
  props: {
    title: {
      type: String,
      default: () => "Undefined",
    },
    body: {
      type: String,
      default: () => "Undefined",
    },
    ok: {
      type: String,
      default: function() {
        return this.$t("common.dialog.ok");
      },
    },
    cancel: {
      type: String,
      default: function() {
        return this.$t("common.dialog.cancel");
      },
    },
    onOk: {
      type: Function,
    },
  },
  computed: {
    formClass: function() {
      return `dform${this.uid}`;
    },
    hasSlotData: function() {
      return this.$slots.default;
    },
  },
  create: function(){
    //this.uid = Math.floor(Math.random() * 1000);
  },
  methods: {
    show: function(val) {
      this.val = val;
      if (this.$refs.dform) {
        this.$refs.dform.resetValidation();
      }
      this.dialog = true;
    },
    okBtn: function() {
      this.dialog = false;

      if (this.hasSlotData) {
        this.$refs.dform.validate();

        if (this.formValid) {
          this.onOk(this.val, this.getVal());
        }
      } else {
        this.onOk(this.val);
      }
    },
    getVal: function() {
      let valObj = {};
      document.querySelectorAll(`.dform${this.uid} [name]`).forEach(element => {
        valObj[element.name] = element.value
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dlg {
  background-color: white !important;
}
</style>
