<template>
  <div>
    <v-datetime-picker v-model="localDate" :label="label" :time-picker-props="timeProps" :tabIndex="tabIndex" @input="udpated">
      <template slot="dateIcon">
        <v-icon>mdi-calendar</v-icon>
      </template>
      <template slot="timeIcon">
        <v-icon>mdi-clock-outline</v-icon>
      </template>
    </v-datetime-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data: function () {
    return {
      timeProps: {
        format: "24hr",
      },
      localDate: null,
    };
  },
  props: {
    label: {
      type: String,
      default: () => "",
    },
    tabIndex: {
      type: Number,
    },
    date: {
      type: Date,
    },
    dateString: {
      type: String,
    },
    onClose: {
      type: Function,
    },
  },
  created() {},
  watch: {
    date(val) {
      if (val) {
        this.localDate = val;
      }
    },
    dateString(val) {
      if (val) {
        this.localDate = moment.parseZone(val).toDate();
      }
    },
  },
  methods: {
    udpated: function (val) {
      let t = moment(val).tz("Europe/Paris").format();

      if (this.date) {
        t = this.localDate;
      }

      this.onClose(t);
    },
  },
};
</script>
