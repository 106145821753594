<template>
  <v-card elevation="0">
    <PanelHeader :onSave="save" :title="$t('calendar.edit.title')" :tabIndex="2005" :onClose="onClose" />
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="appointment.name" :label="$t('calendar.edit.form.title')" :tabIndex="2001" ref="firstfield"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <DTPicker :dateString="appointment.start" :onClose="setStartDate" :label="$t('calendar.edit.form.startDate')" :tabIndex="2002" />
            </v-col>
            <v-col cols="12" md="6">
              <DTPicker :dateString="appointment.end" :onClose="setEndDate" :label="$t('calendar.edit.form.endDate')" :tabIndex="2002" />
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group mandatory row v-model="appointment.type">
                <v-radio label="Private" value="private"></v-radio>
                <v-radio label="Public" value="public"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="appointment.type === 'private'">
              <v-autocomplete
                :items="users"
                v-model="appointment.users"
                :label="$t('calendar.edit.form.users')"
                clearable
                :tabIndex="2004"
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="!projectId">
              <v-select
                v-model="appointment.customerId"
                :items="customers"
                item-value="id"
                item-text="name"
                :label="$t('calendar.edit.form.customer')"
                required
                :tabIndex="2005"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              {{ $t("calendar.edit.form.body") }}
              <ckeditor :editor="editor" v-model="appointment.body" :config="editorConfig" :tabIndex="2006"></ckeditor>
            </v-col>
          </v-row>
        </v-container>
        <small>{{ $t("common.form.requiredText") }}</small>
      </v-form>

      <br />
      <v-alert text icon="mdi-help" border="left" color="green" block>
        {{ $t("calendar.edit.help") }}
      </v-alert>
    </v-card-text>
    <pre class="hidden debbug">{{ appointment }}</pre>
  </v-card>
</template>

<script>
import axios from "axios";

import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";
import DTPicker from "@/components/_common/dtpicker";

export default {
  mixins: [formatMixin, formMixin, richMixin],

  components: {
    PanelHeader,
    DTPicker,
  },

  props: {
    projectId: {
      type: String,
      default: () => null,
    },
    customerId: {
      type: String,
      default: () => null,
    },
    onSuccess: {
      type: Function,
    },
    onClose: {
      type: Function,
    },
  },

  data() {
    return {
      isEdit: false,
      valid: false,
      appointment: {
        body: "",
        start: null,
        end: null,
        name: "",
        customerId: null,
        projectId: null,
      },
      users: [],
      customers: [],
      timeProps: {
        format: "24hr",
      },
    };
  },

  created() {
    this.loadData();
  },

  watch: {
    projectId: function(val) {
      this.appointment.projectId = val;
    },
    customerId: function(val) {
      this.appointment.customerId = val;
    },
  },

  computed: {
    formTitle() {
      return !this.isEdit ? "New appointment" : "Edit appointment";
    },
    us() {
      console.log("asd");
      return this.appointment.users.map((u) => u.id);
    },
  },

  methods: {
    loadData: function() {
      axios
        .get(`/api/users`)
        .then((response) => {
          this.users = response.data.map((s) => {
            return { text: s.name, value: s.id };
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
      axios
        .get(`/api/customers`)
        .then((response) => {
          this.customers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    load: function(params) {
      if (params && params.id) {
        axios
          .get(`/api/appointments/${params.id}`)
          .then((response) => {
            let e = response.data;
            e.start = new Date(e.start);
            e.end = new Date(e.end);
            e.users = e.users.map((u) => u.id);
            this.appointment = e;
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      } else {
        this.appointment = {
          projectId: this.projectId,
          customerId: this.customerId,
          start: null,
          end: null
        };
      }
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.appointment = {
        body: "",
        start: "",
        end: "",
        name: "",
      };
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let prj = { ...this.appointment };
        delete prj.createdAt;
        delete prj.updatedAt;

        axios
          .post(`/api/appointments/${prj.id || ""}`, prj)
          .then((msg) => {
            this.$store.dispatch("showSnackMessage", { message: "Datamsaved." });
            this.onSuccess(msg.data);
            this.onClose();
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },

    setStartDate: function(dt) {
      this.appointment.start = dt;

    },
    setEndDate: function(dt) {
      this.appointment.end = dt;
    },
  },
};
</script>
